import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import Content from '../components/Content';

const ContactThanksPage = () => {
  const {
    site: {
      globalSeo: { titleSuffix },
    },
  } = useStaticQuery(graphql`
    query ContactThanksPageQuery {
      site: datoCmsSite {
        globalSeo {
          titleSuffix
        }
      }
    }
  `);

  return (
    <Layout locale="en">
      <HelmetDatoCms
        title="Thanks for getting in touch"
        titleTemplate={`%s${titleSuffix}`}
      />
      <main>
        <Banner heading="Thanks for getting in touch" />
        <Content html="<p>We will get back to you shortly but in the meantime see what we've been talking about lately by checking out our latest blog posts below.</p>" />
      </main>
    </Layout>
  );
};

export default ContactThanksPage;
