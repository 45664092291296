import React from 'react';
import styled, { css } from 'styled-components';
import { minBreakpointQuery, sectionMargins, brandColours } from '../styles';
import { Container, HtmlContent } from './ui';

const StyledContent = styled.section`
  ${({ isNewsSingle }) =>
    isNewsSingle ? sectionMargins('30px', '50px') : sectionMargins()}
`;

const StyledInner = styled.div`
  ${({ twoColumns }) => {
    if (twoColumns) {
      return css`
      
        ${minBreakpointQuery.mlarge`
          column-count: 2;
          column-gap: 60px;
        `}

        ${minBreakpointQuery.large`
          column-gap: 80px;
        `}

        ${minBreakpointQuery.xxlarge`
          column-gap: 100px;
        `}
      `;
    }
  }};

  ${({ highlight }) => {
    if (highlight) {
      return css`
        padding: 30px;
        background-color: ${brandColours.octonary};

        ${minBreakpointQuery.small`
          padding: 40px;
        `}

        ${minBreakpointQuery.medium`
          padding: 60px;
        `}

        ${minBreakpointQuery.large`
          padding: 80px;
        `}

        ${minBreakpointQuery.xxlarge`
          padding: 100px;
        `}
      `;
    }
  }};
`;

const Content = ({ html, twoColumns, highlight, contain, isNewsSingle }) => (
  <StyledContent isNewsSingle={isNewsSingle}>
    <Container narrow={(contain && !highlight) || isNewsSingle}>
      <StyledInner twoColumns={twoColumns} highlight={highlight}>
        <HtmlContent html={html} />
      </StyledInner>
    </Container>
  </StyledContent>
);

export default Content;
